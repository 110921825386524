import React, { Component } from "react";
import ReactDOM from "react-dom";
import Pushboard from "./Pushboard/pushboard.js";
import "./Bio.css";

class Bio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  loadPushboard() {
    ReactDOM.render(<Pushboard />, document.getElementById("content"));
    this.setState({ open: true });
  }

  deletePushboard() {
    ReactDOM.render(null, document.getElementById("content"));
    this.setState({ open: false });
  }

  render() {
    return (
      <div className={`HeaderColumn`}>
        <center>
          <h1> Tiange John Wu </h1>
        </center>
        <a
          onClick={() =>
            this.state.open ? this.deletePushboard() : this.loadPushboard()
          }
        >
          {" "}
          pushboard {this.state.open ? "(x)" : null}{" "}
        </a>
      </div>
    );
  }
}

export default Bio;
