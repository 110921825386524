import React, { Component } from "react";
import Bio from "./components/Bio.js";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="Column">
          <Bio />
          <div id="content" />
        </div>
      </div>
    );
  }
}

export default App;
